// src/api/chatApi.js
import BASE_URL from "../config";

const chatApi = {
    sendMessage: async (chatId, message, onMessageChunk) => {
        try {
            // Perform the API request to send the message and get the streaming response
            const response = await fetch(`${BASE_URL}/chat`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ chat_id: chatId, message: message })
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            // Handle the streaming response using ReadableStream
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');

            // Recursive function to process each chunk as it arrives
            function processChunk({ done, value }) {
                if (done) return;

                // Decode the chunk and process it
                const chunk = decoder.decode(value, { stream: true });

                // Accumulate the chunk (you can tweak this based on your sentence-endings check logic)
                onMessageChunk(chunk);

                // Continue reading the next chunk
                return reader.read().then(processChunk);
            }

            // Start reading the stream
            reader.read().then(processChunk);

        } catch (error) {
            console.error("Error receiving message:", error);
            throw error;
        }
    },
};

export default chatApi;
