import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import backgroundImage from "../images/cover/backGround2.png";
import homeIcon from "../images/cover/homeIcon.png";
import sendIcon from "../images/cover/sendMessage.png";
import chatApi from "../api/chatApi";


const ChatBox = () => {
  const [name, setName] = useState("");
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chatId, setChatId] = useState("");
  const inputRef = useRef(null);
  const [typing, setTyping] = useState(false);  // Add state for typing animation



  const maxMessages = 15;

  useEffect(() => {
    let storedChatId = sessionStorage.getItem("chat_id");

    if (!storedChatId) {
      storedChatId = uuidv4();
      sessionStorage.setItem("chat_id", storedChatId); // Store in sessionStorage
    }

    setChatId(storedChatId);

    const handleBeforeUnload = () => {
      sessionStorage.removeItem("chat_id");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      sessionStorage.removeItem("chat_id");
    };
  }, []);

  const focusInputField = () => {
    if (inputRef.current) {
      // console.log('Attempting to focus input...');
      setTimeout(() => {
        inputRef.current.focus();
        // console.log('Input focused.');
      }, 100);
    } else {
      // console.log('inputRef is not available.');
    }
  };
  const parentRef = useRef(null);
  const chatBoxRef = useRef(null);


  useEffect(() => {
    inputRef.current.focus();
    parentRef.current.scrollTop = parentRef.current.scrollHeight;
    scrollToBottom();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleInputChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name.trim() && messages.length < maxMessages) {
      try {
        setLoading(true);
        setTyping(true);
        setError(null);

        const userMessage = {
          text: name,
          sender: "user",
        };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        // Reset input
        setName("");

        // API call with streaming response handling
        await chatApi.sendMessage(chatId, name, (newChunk) => {
          // Append the new chunk of response from the API to the chat UI
          const apiMessage = {
            text: newChunk,
            sender: "api",
          };
          setMessages((prevMessages) => {
            const lastMessage = prevMessages[prevMessages.length - 1];

            // If the last message was from the API, append to it
            if (lastMessage?.sender === "api") {
              const updatedLastMessage = {
                ...lastMessage,
                text: lastMessage.text + newChunk,
              };
              return [...prevMessages.slice(0, -1), updatedLastMessage];
            } else {
              // Otherwise, create a new API message
              return [...prevMessages, apiMessage];
            }
          });
        });

      } catch (err) {
        setError("Failed to send message. Please try again.");
        console.error(err);
      } finally {
        setLoading(false);
        setTyping(false);
        focusInputField();
      }
    }
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  const scrollToBottom = () => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  };

  const progressPercentage = (messages.length / maxMessages) * 100;

  return (
    <div
      className="flex justify-center items-center h-screen bg-cover bg-center bg-black"
      // style={{ backgroundImage: `url(${backgroundImage})` }}
      ref={parentRef}
    >
      <motion.button
        onClick={handleHomeClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.4, ease: "easeOut" }}
        className="absolute top-4 left-4 text-white p-3 rounded-full"
        style={{ zIndex: 10 }}
      >
        <img src={homeIcon} alt="Home" className="w-6 h-6" />
      </motion.button>

      <motion.div
        className="max-w-lg w-full mx-4 p-6 rounded-xl relative h-screen flex flex-col"
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 100, opacity: 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <div className="text-center text-white mb-4">
          <h2 className="text-400 font-bold">Harvey AI</h2>
          <div className="w-full bg-gray-700 h-2 hidden sm:block rounded-full mt-2">
            <div
              className="bg-[#FFFFFF] h-1 rounded-full"
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>
        </div>

        <div
          ref={chatBoxRef} // Assign ref to the chat container
          className="flex-1 overflow-auto pt-4 custom-scrollbar"
        >
          {!messages.length && (
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
              className="text-start text-white"
            >
              <p className="text-md">
                Hey! I’m Harvey.👋 Let’s Get You the Best Mortgage in UAE.
              </p>
              <p className="mt-2 text-md">
                Please remember that all your data is securely stored and
                handled with the utmost confidentiality.
              </p>
              <p className="mt-4 text-md">
                Let’s start with your name. What should I call you?
              </p>
            </motion.div>
          )}

            <div className="space-y-2">
              {messages.map((msg, index) => (
                  <div
                      key={index}
                      className={`flex ${
                          msg.sender === "user" ? "justify-end" : "justify-start"
                      }`}
                  >
                    <div
                        className={`chat-message text-[#FFFFFF] p-2 rounded-lg text-left ${
                            msg.sender === "user"
                                ? "bg-[#FFFFFF14] ml-2"
                                : "bg-[#333] mr-2"
                        }`}
                    >
                      <p>{msg.text}</p>
                    </div>
                  </div>
              ))}
              {typing && (
                  <div className="flex justify-start">
                    <div className="chat-message text-[#FFFFFF] p-2 rounded-lg text-left bg-[#333] mr-2">
                      <div className="typing-indicator">
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </div>
                    </div>
                  </div>
              )}
            </div>

            {error && <p className="text-red-500 text-center mt-4">{error}</p>}
          </div>
          <div className="text-center text-white mb-4">
            <div className="w-full bg-gray-700 h-2 block sm:hidden rounded-full mt-2">
              <div
                  className="bg-[#FFFFFF] h-1 rounded-full"
                  style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="w-full">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8, duration: 0.4 }}
                className="relative"
            >
              <input
                  ref={inputRef}
                  type="text"
                  placeholder="Enter your message"
                  value={name}
                  onChange={handleInputChange}
                  className="w-full bg-gray-800 text-white p-3 rounded-full outline-none focus:ring-2"
                  disabled={loading || messages.length >= maxMessages}  // Ensure it's not disabled when focusing
              />

              <button
                  type="submit"
                  className={`absolute right-0 bottom-2 text-white px-4 rounded-full ${
                      messages.length >= maxMessages || loading
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                  }`}
                  disabled={messages.length >= maxMessages || loading}
              >
                {loading ? (
                    <div className="loader"></div>
                ) : (
                    <img src={sendIcon} alt="Send" className="w-8 h-8" />
                )}
            </button>
          </motion.div>
        </form>
      </motion.div>
    </div>
  );
};

export default ChatBox;
