import React, { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = (id) => {
    setIsOpen(false);
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <header className="absolute top-0 left-0 w-full flex items-center justify-between p-4 lg:px-24 bg-opacity-50 z-10">
      <div className="text-white font-bold text-xl">Funded.ae</div>

      <div className="text-white lg:hidden">
        <button onClick={toggleMenu}>
          {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>
      </div>

      <nav
        className={`${
          isOpen ? "block" : "hidden"
        } lg:flex lg:items-center lg:space-x-1 pb-3 lg:pb-0 text-white absolute lg:static top-14 left-0 w-full lg:w-auto bg-black lg:bg-transparent bg-opacity-90 lg:bg-opacity-0 transition-all duration-300`}
      >
        <a
          href="#"
          onClick={() => handleScroll("why-funded")}
          className="block px-4 py-2 hover:underline lg:inline"
        >
          Why Funded
        </a>
        <a
          href="#"
          onClick={() => handleScroll("mortgage-calculator")}
          className="block px-4 py-2 hover:underline lg:inline"
        >
          Mortgage Calculator
        </a>
        <a
          href="#"
          onClick={() => handleScroll("success-stories")}
          className="block px-4 py-2 hover:underline lg:inline"
        >
          Success Stories
        </a>
        <a
          href="#"
          onClick={() => handleScroll("faqs")}
          className="block px-4 py-2 hover:underline lg:inline"
        >
          FAQs
        </a>

        <button
          onClick={() => navigate("/chat")}
          className="bg-white text-black py-2 px-6 rounded-full font-medium hover:bg-gray-200 block lg:inline"
        >
          Get Started
        </button>
      </nav>
    </header>
  );
};

export default Header;
