import React from "react";
import backgroundImage from "../images/cover/backGround2.png";
const Footer = () => {
  return (
    <footer
      className="border border-t-2 border-[#FFFFFF14] text-white py-4 bg-black"
      // style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="container mx-auto text-center">
        <p className="mb-2 font-marcellus  text-[24px] font-normal leading-[34.76px] text-center">
          Funded.ae
        </p>

        <div className="flex justify-center space-x-4">
          <span className="font-normal text-[#FFFFFFB2]">© 2024 Funded.ae</span>
          <a href="#" className="hover:underline font-normal text-[#FFFFFFB2]">
            Terms
          </a>
          <a href="#" className="hover:underline font-normal text-[#FFFFFFB2]">
            Privacy
          </a>
          <a href="#" className="hover:underline font-normal text-[#FFFFFFB2]">
            Support
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
