import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import ChatBox from "./components/ChatBox";

// Lazy load Cover component
const Cover = lazy(() => import("./components/Cover"));

function App() {
  const location = useLocation();

  // Check if the current path is "/chat"
  const isChatPage = location.pathname === "/chat";

  return (
    <div className="App">
      {/* Conditionally render Header and Footer only if not on "/chat" */}
      {!isChatPage && <Header />}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Cover />} />
          <Route path="/chat" element={<ChatBox />} />
          {/* <Route path="/contact" element={<Contact />} /> */}
          {/* Add more routes as needed */}
        </Routes>
      </Suspense>
      {!isChatPage && <Footer />}
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
